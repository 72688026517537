import React from 'react';
import Helmet from 'react-helmet';
import {siteMetadata} from '../../data/en';

class Seo extends React.Component {
  render() {
    const {title, description, url, lang, preview} = siteMetadata;

    return (
      <Helmet
        htmlAttributes={{
          lang
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description
          },
          {
            property: `og:title`,
            content: title
          },
          {
            property: `og:description`,
            content: description
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            property: `og:url`,
            content: url
          },
          {
            property: `og:image`,
            content: preview
          }
        ]}
      />
    );
  }
}

export default Seo;
